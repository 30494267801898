document.addEventListener("turbolinks:load", function() {
  const feedContainer = document.querySelector('.dashboard-screen-rivals-feed');
  if (feedContainer) {
    const banner = document.querySelector('.upgrade-banner');
    if (banner) {
      // $rivals-pane-width
      banner.style.marginLeft = '240px';
      banner.style.width = 'calc(100% - 240px)';
      // $upgrade-banner-width
      feedContainer.style.top = '40px';
    }
  }
});
