document.addEventListener("turbolinks:load", function() {
  const navbar = document.querySelector(".navbar");
  const pane = document.querySelector(".dashboard-screen-rivals-pane");
  if (navbar && pane) {
    const allRivalsLink = document.querySelector(".dashboard-screen-rivals-pane-link a");
    const navbarLinks = [...document.querySelectorAll(".navbar-links-left a")];
    const rivalsPaneLinks = [...document.querySelectorAll("a.dashboard-screen-rivals-pane-link")];
    navbarLinks.forEach(link => {
      if (window.location.href.includes(link.href)) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
    rivalsPaneLinks.forEach(link => {
      if (window.location.href.includes(link.href)) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
    if (window.location.href === allRivalsLink.href) {
      allRivalsLink.parentNode.classList.add("active");
    } else {
      allRivalsLink.parentNode.classList.remove("active");
    }
  }
});
