const showRivalEditModal = (id) => {
  const view = document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view`);
  const closeBtn = document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .close-edit-view`);
  const toggleAllPathsBtn = document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view #toggle-all-paths-btn`);
  const collapseTds = document.querySelectorAll(`#id_${id}.dashboard-screen-rivals-edit-view td.collapse`);
  const rivalTrackingToggle = document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view #follow-switch input`);
  const pathsListToggle = document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view #paths-switch input`);
  const saveChangesBtn = document.querySelector(
    `#id_${id}.dashboard-screen-rivals-edit-view .dashboard-screen-rivals-edit-view-form-screen .form-screen-buttons-container .form-screen-submit-button`
  );
  const removeRivalBtn = document.querySelector(
    `#id_${id}.dashboard-screen-rivals-edit-view .dashboard-screen-rivals-edit-view-form-screen .form-screen-buttons-container .form-screen-cancel-button`
  );

  view.classList.add("show");
  $(closeBtn).click(() => {
    $(pathsListToggle).prop("checked", false);
    document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .form-screen-edit-paths-table-container`).style.display = "none";
    $(closeBtn).unbind();
    $(toggleAllPathsBtn).unbind();
    $(rivalTrackingToggle).unbind();
    $(pathsListToggle).unbind();
    view.classList.remove("show");
  });

  $(toggleAllPathsBtn).click((e) => {
    e.preventDefault();
    if (toggleAllPathsBtn.classList.contains("notCollapsed")) {
      collapseTds.forEach((elem) => {
        elem.classList.add("show");
      });
      toggleAllPathsBtn.classList.remove("notCollapsed");
    } else {
      collapseTds.forEach((elem) => {
        elem.classList.remove("show");
      });
      toggleAllPathsBtn.classList.add("notCollapsed");
    }
  });

  $(rivalTrackingToggle).change(function () {
    if (this.checked) {
      document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .form-screen-checkbox-info`).classList.remove("show");
      saveChangesBtn.style.display = "block";
      removeRivalBtn.style.display = "none";
    } else {
      document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .form-screen-checkbox-info`).classList.add("show");
      saveChangesBtn.style.display = "none";
      removeRivalBtn.style.display = "block";
    }
  });

  $(pathsListToggle).change(function () {
    if (this.checked) {
      document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .form-screen-edit-paths-table-container`).style.display = "block";
    } else {
      document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view .form-screen-edit-paths-table-container`).style.display = "none";
    }
  });
};

document.addEventListener("turbolinks:load", function () {
  const regex = /index\/\d*/g;
  if (window.location.href.match(regex)) {
    const regexUrl = /.*dashboard\/index/g;
    let href = window.location.href.match(regexUrl)[0];
    href = href.slice(0, -6);
    href += "/edit_modal_lazy_load";
    const idString = window.location.href.match(regex)[0];
    const id = parseInt(idString.slice(6));
    $.ajax({
      type: "GET",
      url: `${href}?rival=${id}`,
      data: { error: true },
      success: function () {
        const errors = document.querySelector("#error_explanation");
        if (errors) {
          showRivalEditModal(id);
        }
      },
    });
  }
  let pane = document.querySelector(".dashboard-screen-rivals-pane");
  if (pane) {
    const editBtns = [...document.querySelectorAll(".dashboard-screen-rivals-pane-link-edit-rival-link")];
    editBtns.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();

        const idString = btn.getAttribute("id");
        const id = parseInt(idString.slice(3));

        if (!document.querySelector(`#id_${id}.dashboard-screen-rivals-edit-view`)) {
          $.ajax({
            type: "GET",
            url: `${$(btn).attr("href")}?rival=${id}`,
            dataType: "script",
            success: function () {
              showRivalEditModal(id);
            },
          });
        } else {
          showRivalEditModal(id);
        }
      });
    });
  }
});

window.togglePathsSelection = function (mainCheckbox, parentId = null) {
  let selector;
  if (parentId) {
    selector = `[data-hierarchy*="%${parentId}%"] input[type="checkbox"][name*="rival[paths_attributes]"][name*="[selected]"]`;
    selector += `, tr[data-path-id="${parentId}"] input[type="checkbox"][name*="rival[paths_attributes]"][name*="[selected]"]`;
  } else selector = 'input[type="checkbox"][name*="rival[paths_attributes]"][name*="[selected]"]';
  const checkboxes = Array.from(document.querySelectorAll(selector));
  for (let checkbox of checkboxes) {
    checkbox.checked = mainCheckbox.checked;
  }
};
