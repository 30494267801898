const checkIfCardFits = (card, loaded = false) => {
  const content = card.querySelector(".dashboard-screen-rivals-feed-card-content");
  const btn = card.querySelector(".dashboard-screen-rivals-feed-card-expand-button");
  const scrollHeight = content.scrollHeight;
  const height = content.offsetHeight + 1;
  const type = card.getAttribute("data-type");
  if (loaded) {
    if (!btn.classList.contains("active")) {
      if (scrollHeight === height || type === "rival_added" || card.dataset.shouldcollapse == "false") {
        btn.style.visibility = "hidden";
        content.querySelector(".linear-background").style.display = "none";
        $(content).addClass("show");
      } else {
        card.querySelector(".dashboard-screen-rivals-feed-card-expand-button").style.visibility = "visible";
        content.querySelector(".linear-background").style.display = "block";
        content.querySelector(".linear-background").classList.add("fade-out");
      }
    }
  }
};

const loadImages = card => {
  const content = card.querySelector(".dashboard-screen-rivals-feed-card-content");
  const imgs = [...$(content).find("img")];
  const length = imgs.length;
  let counter = 0;

  if (length == 0) {
    checkIfCardFits(card, true);
    return;
  }

  [].forEach.call(imgs, function(img) {
    if (img.complete) {
      incrementCounter();
    } else {
      img.addEventListener("error", incrementCounter, false);
      img.addEventListener("load", incrementCounter, false);
    }
  });

  function incrementCounter() {
    counter++;
    if (counter === length) {
      checkIfCardFits(card, true);
    }
  }
};

document.addEventListener("turbolinks:load", function() {
  const feedScreen = document.querySelector(".dashboard-screen-rivals-feed");
  if (feedScreen) {
    let feedCards = [...document.querySelectorAll(".dashboard-screen-rivals-feed-card")];
    feedCards.forEach(card => {
      let content = card.querySelector(".dashboard-screen-rivals-feed-card-content");
      let btn = card.querySelector(".dashboard-screen-rivals-feed-card-expand-button");
      loadImages(card);
      let accordions = card.querySelectorAll(".accordion")
      btn.addEventListener("click", function() {
        $(btn).toggleClass("active");
        $(content.querySelector(".linear-background")).toggleClass("fade-out");
        $(content.querySelector(".linear-background")).toggleClass("fade-in");
        if ($(content).hasClass("show")) {
          card.querySelectorAll(".collapse").forEach((collapsibleElement) => {
            collapsibleElement.classList.remove("show");
          })
        }
        $(content).toggleClass("show");
        card.scrollIntoView({ behavior: 'smooth' });
      });
      if (accordions.length > 0) {
        accordions.forEach((accordion) => {
          accordion.addEventListener("click", function () {
            if (!$(content).hasClass('show')) {
              $(btn).toggleClass("active");
              $(content.querySelector(".linear-background")).toggleClass("fade-out");
              $(content.querySelector(".linear-background")).toggleClass("fade-in");
              $(content).toggleClass("show");
            }
          })
        })
      }
    });

    const targetNode = feedScreen;
    const config = { attributes: false, childList: true, subtree: true };

    const callback = function(mutationsList, observer) {
      mutationsList.forEach(mutation => {
        if (mutation.type === "childList") {
          elementsArr = [...mutation.addedNodes];

          elementsArr.forEach(elem => {
            if (elem.nodeType === 1) {
              if (elem.classList.contains("no-more-updates-info")) {
                observer.disconnect();
              } else {
                loadImages(elem);
                let content = elem.querySelector(".dashboard-screen-rivals-feed-card-content");
                let btn = elem.querySelector(".dashboard-screen-rivals-feed-card-expand-button");
                btn.addEventListener("click", function() {
                  $(btn).toggleClass("active");
                  $(content.querySelector(".linear-background")).toggleClass("fade-out");
                  $(content.querySelector(".linear-background")).toggleClass("fade-in");
                  $(content).toggleClass("show");
                });
              }
            }
          });
        }
      });
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
});
