// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("js-cookie");
// require("active_admin").start()
import "dashboard_welcome/dashboard_welcome.js";
import "active_button_change/active_button_change.js";
import "dashboard_reports_view/masonry.js";
import "dashboard_analytics/inputs_behaviour.js";
import "dashboard_analytics/analytics_search_by_time.js";
import "dashboard_analytics/analytics_search_by_tags.js";
import "dashboard_analytics/flatpickr_script.js";
import "dashboard/responsive_design.js";
import "dashboard/feed_expand.js";
import "dashboard/accordion.js";
import "edit_rival/edit_rival.js";
import "edit_rival/new_rival.js";
import "dashboard/load_more_feed.js";
import "upgrade_banner/banner.js";
import "new_rival/check_scrape_status.js";
import "dashboard/iframe_lazy_load.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
