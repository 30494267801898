document.addEventListener("turbolinks:load", function() {
  const navbar = document.querySelector(".navbar");
  if (navbar) {
    const openMenuRivalsBtn = document.querySelector(".navbar-logo-menu-open-button");
    const openMenuAppBtn = document.querySelector(".navbar-links-menu-open-button");
    openMenuRivalsBtn.addEventListener("click", () => {
      document.querySelector(".dashboard-screen-rivals-pane").classList.toggle("open");
      document.querySelector(".navbar-links-left").classList.remove("open");
    });
    openMenuAppBtn.addEventListener("click", () => {
      openMenuAppBtn.querySelector("i").classList.toggle("fa-caret-square-up");
      openMenuAppBtn.querySelector("i").classList.toggle("fa-bars");
      document.querySelector(".navbar-links-left").classList.toggle("open");
      document.querySelector(".dashboard-screen-rivals-pane").classList.remove("open");
    });
  }
});
