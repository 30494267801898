document.addEventListener("turbolinks:load", async function() {
  if (document.querySelector('div.rival-wait-screen')) {
    // On rival wait page
    window.setInterval(() => {
      const rivalId = window.location.pathname.match(/\d+/)[0];
      $.get(`/rivals/${rivalId}/initial_scrape_status`, function(data){
        if (data.finished === true) {
          window.location = `/dashboard/index?edit_rival_${rivalId}`;
        }
      });
    }, 5000);
  } else if (window.location.href.match(/dashboard\/index\?edit_rival_\d+/)) {
    // On dashboard index page after being redirected from rival wait page
    const rivalId = window.location.search.match(/\d+/)[0];
    // Show edit rival modal
    document.querySelector(`a#id_${rivalId}`).click();
    // Show paths
    for (let i = 0; i < 20; i++) {
      await new Promise(r => setTimeout(r, 100));
      if (document.querySelector('#paths-switch > span')) {
        document.querySelector('#paths-switch > span').click();
        return;
      }
    }
  }
});
