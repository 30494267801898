document.addEventListener("turbolinks:load", function() {
  let dashboardHeader = document.querySelector(".dashboard-analytics-rivals-feed-header");
  if (dashboardHeader) {
    const tags = [...document.querySelectorAll(".dashboard-analytics-dropdown .dashboard-analytics-dropdown-option")];
    const feedCards = [...document.querySelectorAll(".dashboard-screen-rivals-analytics-feed-content .dashboard-analytics-rivals-analytics-feed-card")];

    tags.forEach(tag => {
      let selectionText = document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value;
      if (selectionText.includes(tag.innerText)) {
        tag.classList.add("active");
      }

      tag.addEventListener("click", function(event) {
        document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").focus();
        selectionText = document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value;
        newText = "";
        if (tag.innerText === "All Rivals") {
          if (!tag.classList.contains("active")) {
            tags.forEach(tag => {
              tag.classList.remove("active");
            });
            tag.classList.add("active");
          }
          document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = "All Rivals";
          feedCards.forEach(card => {
            card.style.display = "block";
          });
        } else {
          if (tags[0].classList.contains("active")) {
            newText = selectionText.replace("All Rivals", "");
            document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = newText;
          } else {
            newText = selectionText.replace("All Rivals, ", "");
            document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = newText;
          }
          tags[0].classList.remove("active");
          if (tag.classList.contains("active")) {
            let lastOne = false;
            let counter = 0;
            tags.forEach(tag => {
              if (tag.classList.contains("active")) counter++;
            });
            counter === 1 ? (lastOne = true) : (lastOne = false);
            if (lastOne) {
              tag.classList.remove("active");
              tags[0].classList.add("active");
              document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = "All Rivals";
              feedCards.forEach(card => {
                card.style.display = "block";
              });
            } else {
              tag.classList.remove("active");
              if (selectionText.indexOf(tag.innerText) + tag.innerText.length !== selectionText.length) {
                newText = selectionText.replace(`${tag.innerText}, `, "");
                document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = newText;
              } else {
                newText = selectionText.replace(`, ${tag.innerText}`, "");
                document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value = newText;
              }
              let selectedTags = document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value.split(", ");
              feedCards.forEach(card => {
                card.style.display = "none";
                if (selectedTags.includes(card.querySelector(".dashboard-screen-rivals-feed-card-header-text-description b").innerText)) {
                  card.style.display = "block";
                }
              });
            }
          } else {
            tag.classList.add("active");
            if (document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value.length > 0) {
              document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value += `, ${tag.innerText}`;
            } else {
              document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value += `${tag.innerText}`;
            }
            let selectedTags = document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value.split(", ");
            feedCards.forEach(card => {
              card.style.display = "none";
              if (selectedTags.includes(card.querySelector(".dashboard-screen-rivals-feed-card-header-text-description b").innerText)) {
                card.style.display = "block";
              }
            });
          }
        }
      });
    });
  }
});
