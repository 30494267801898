import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", function() {
  let dashboardHeader = document.querySelector(".dashboard-analytics-rivals-feed-header");
  if (dashboardHeader) {
    flatpickr(".datepicker", {
      maxDate: "today"
    });
  }
});
