document.addEventListener("turbolinks:load", function() {
  let dashboardHeader = document.querySelector(".dashboard-analytics-rivals-feed-header");
  if (dashboardHeader) {
    const headerButtons = [...document.querySelectorAll(".dashboard-analytics-feed-header-list-button")];
    const headerInputs = [...document.querySelectorAll(".dashboard-analytics-feed-header-list-input")];

    headerButtons.forEach(btn => {
      btn.addEventListener("click", function() {
        if (!btn.classList.contains("active")) {
          headerButtons.forEach(btn => {
            btn.classList.remove("active");
          });
        }
        btn.classList.toggle("active");
        if (btn.classList.contains("active")) document.querySelector(`input.dashboard-analytics-feed-header-list-input#${btn.id}`).focus();
        if (btn.id === "rivals") {
          if (!btn.classList.contains("active")) {
            document.querySelector(".dashboard-analytics-dropdown").classList.remove("show");
          } else {
            document.querySelector(".dashboard-analytics-dropdown").classList.add("show");
          }
        } else {
          document.querySelector(".dashboard-analytics-dropdown").classList.remove("show");
        }
      });
    });

    headerInputs.forEach(input => {
      input.addEventListener("focusin", function() {
        headerButtons.forEach(btn => {
          btn.classList.remove("active");
        });
        document.querySelector(`.dashboard-analytics-feed-header-list-button#${input.id}`).classList.add("active");
        if (input.id === "rivals") {
          document.querySelector(".dashboard-analytics-dropdown").classList.add("show");
        } else {
          document.querySelector(".dashboard-analytics-dropdown").classList.remove("show");
        }
      });
      input.addEventListener("focusout", function() {
        if (input.id !== "rivals") {
          headerButtons.forEach(btn => {
            btn.classList.remove("active");
          });
          document.querySelector(".dashboard-analytics-dropdown").classList.remove("show");
        }
      });
    });
  }
});
