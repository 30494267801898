import Cookies from "js-cookie";

document.addEventListener("turbolinks:load", function() {
  let dashboardWelcome = document.querySelector(
    ".dashboard-screen-rivals-feed-welcome"
  );
  if (dashboardWelcome) {
    let userId = dashboardWelcome.getAttribute("data-user-id");
    if (
      localStorage.getItem("popState") !== "shown" &&
      document.cookie.indexOf(`${userId}=`) === -1
    ) {
      localStorage.setItem("popState", "shown");
      dashboardWelcome.classList.remove("hide");
      document
        .querySelector(".close-dashboard-welcome-button")
        .addEventListener("click", function() {
          dashboardWelcome.classList.add("hide");
        });
      document
        .querySelector(".never-show-dashboard-welcome-button")
        .addEventListener("click", function() {
          Cookies.set(userId, "shown", { expires: 360 });
          dashboardWelcome.classList.add("hide");
        });
    }
    document
      .querySelector('.navbar-dropdown-content a[data-method="delete"')
      .addEventListener("click", function() {
        localStorage.clear();
      });
  }
});
