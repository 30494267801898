import moment from "moment/moment";

document.addEventListener("turbolinks:load", function() {
  let dashboardHeader = document.querySelector(".dashboard-analytics-rivals-feed-header");
  if (dashboardHeader) {
    const timeInputs = [...document.querySelectorAll(".dashboard-analytics-feed-header-list-input#from, .dashboard-analytics-feed-header-list-input#to")];
    timeInputs.forEach(input => {
      input.addEventListener("focusout", function() {
        const rivalInput = document.querySelector(".dashboard-analytics-feed-header-list-input#rivals").value.split(", ");
        const fromInput = document.querySelector(".dashboard-analytics-feed-header-list-input#from").value;
        const toInput = document.querySelector(".dashboard-analytics-feed-header-list-input#to").value;
        const feedCards = [...document.querySelectorAll(".dashboard-screen-rivals-analytics-feed-content .dashboard-analytics-rivals-analytics-feed-card")];

        // checking the value of rivals input to show only these rivals

        if (rivalInput.includes("All Rivals")) {
          feedCards.forEach(card => {
            card.style.display = "block";
          });
        } else {
          feedCards.forEach(card => {
            card.style.display = "none";
            if (rivalInput.includes(card.querySelector(".dashboard-screen-rivals-feed-card-header-text-description b").innerText)) {
              card.style.display = "block";
            }
          });
        }

        //time checks

        if (fromInput !== "" || toInput !== "") {
          if (moment(fromInput) <= moment(toInput) || fromInput === "" || toInput === "") {
            feedCards.forEach(card => {
              let allCount = 0;
              let newPageCount = 0;
              let updateCount = 0;
              const allDateSpanText = card.querySelector("#all.dashboard-analytics-feed-content-list-answer .when_modified").innerText.split(", ");
              const newPageDateSpanText = card.querySelector("#newPage.dashboard-analytics-feed-content-list-answer .when_modified").innerText.split(", ");
              const updateDateSpanText = card.querySelector("#updatePage.dashboard-analytics-feed-content-list-answer .when_modified").innerText.split(", ");

              allDateSpanText.forEach(date => {
                date = date === "Today" ? moment() : date;
                date = date === "Yesterday" ? moment().subtract(1, "days") : date;
                date = moment(date, "DD/MM/YYYY");
                if (fromInput === "") {
                  if (moment(date).isSameOrBefore(moment(toInput), "day")) {
                    allCount++;
                  }
                } else if (toInput === "") {
                  if (moment(date).isSameOrAfter(moment(fromInput), "day")) {
                    allCount++;
                  }
                } else {
                  if (moment(date).isBetween(moment(fromInput), moment(toInput), "day", [])) {
                    allCount++;
                  }
                }
              });
              card.querySelector("#all.dashboard-analytics-feed-content-list-answer div").innerText = allCount;

              newPageDateSpanText.forEach(date => {
                date = date === "Today" ? moment() : date;
                date = date === "Yesterday" ? moment().subtract(1, "days") : date;
                date = moment(date, "DD/MM/YYYY");
                if (fromInput === "") {
                  if (moment(date).isSameOrBefore(moment(toInput), "day")) {
                    newPageCount++;
                  }
                } else if (toInput === "") {
                  if (moment(date).isSameOrAfter(moment(fromInput), "day")) {
                    newPageCount++;
                  }
                } else {
                  if (moment(date).isBetween(moment(fromInput), moment(toInput), "day", [])) {
                    newPageCount++;
                  }
                }
              });
              card.querySelector("#newPage.dashboard-analytics-feed-content-list-answer div").innerText = newPageCount;

              updateDateSpanText.forEach(date => {
                date = date === "Today" ? moment() : date;
                date = date === "Yesterday" ? moment().subtract(1, "days") : date;
                date = moment(date, "DD/MM/YYYY");
                if (fromInput === "") {
                  if (moment(date).isSameOrBefore(moment(toInput), "day")) {
                    updateCount++;
                  }
                } else if (toInput === "") {
                  if (moment(date).isSameOrAfter(moment(fromInput), "day")) {
                    updateCount++;
                  }
                } else {
                  if (moment(date).isBetween(moment(fromInput), moment(toInput), "day", [])) {
                    updateCount++;
                  }
                }
              });
              card.querySelector("#updatePage.dashboard-analytics-feed-content-list-answer div").innerText = updateCount;

              if (card.querySelector("#all.dashboard-analytics-feed-content-list-answer div").innerText === "0") {
                card.style.display = "none";
              }
            });
          }
        }
      });
    });
  }
});
