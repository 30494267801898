document.addEventListener("turbolinks:load", function() {
  const dashboardContent = document.querySelector(".dashboard-screen-rivals-reports-content");
  if (dashboardContent) {
    const cards = [...document.querySelectorAll(".dashboard-screen-rivals-reports-card")];
    let cardsNumber = cards.length;
    let rows = Math.ceil(cardsNumber / 3);
    if (window.innerWidth < 1520) {
      rows = Math.ceil(cardsNumber / 2);
    }
    let maxArr = new Array(rows + 1);
    for (let i = 0; i < rows + 1; i++) {
      let temp = [];
      cards.forEach(card => {
        if (!maxArr.includes(card.offsetHeight)) temp.push(card.offsetHeight);
      });
      maxArr[i] = temp.length > 0 ? Math.max(...temp) : maxArr[i - 1];
    }
    let sum = 0;
    for (let i = 0; i < rows + 1; i++) {
      if (rows === 2) {
        if (i !== 0) sum += maxArr[i];
      } else {
        if (maxArr[2] !== maxArr[3]) {
          if (i !== rows) sum += maxArr[i];
        } else {
          if (i !== rows && i !== rows - 1) sum += maxArr[i];
        }
      }
    }
    if (sum <= maxArr[0]) {
      dashboardContent.style.maxHeight = `${maxArr[0]}px`;
    } else {
      dashboardContent.style.maxHeight = `${sum}px`;
    }
    window.addEventListener("resize", function() {
      const cards = [...document.querySelectorAll(".dashboard-screen-rivals-reports-card")];
      let cardsNumber = cards.length;
      let rows = Math.ceil(cardsNumber / 3);
      if (window.innerWidth < 1520) {
        rows = Math.ceil(cardsNumber / 2);
      }
      let maxArr = new Array(rows + 1);
      for (let i = 0; i < rows + 1; i++) {
        let temp = [];
        cards.forEach(card => {
          if (!maxArr.includes(card.offsetHeight)) temp.push(card.offsetHeight);
        });
        maxArr[i] = temp.length > 0 ? Math.max(...temp) : maxArr[i - 1];
      }
      let sum = 0;
      for (let i = 0; i < rows + 1; i++) {
        if (rows === 2) {
          if (i !== 0) sum += maxArr[i];
        } else {
          if (maxArr[2] !== maxArr[3]) {
            if (i !== rows) sum += maxArr[i];
          } else {
            if (i !== rows && i !== rows - 1) sum += maxArr[i];
          }
        }
      }
      if (sum <= maxArr[0]) {
        dashboardContent.style.maxHeight = `${maxArr[0]}px`;
      } else {
        dashboardContent.style.maxHeight = `${sum}px`;
      }
    });
  }
});
