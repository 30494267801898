document.addEventListener("turbolinks:load", function() {
  const btn = document.querySelector(".form-screen-another-rival-button");
  if (btn) {
    const hrefReceived = window.location.search;
    const hrefParams = new URLSearchParams(hrefReceived);
    const numberPrevious = document.querySelectorAll(".form-screen-inputs-field-container").length - 1;
    if ([...hrefParams].length > 0) {
      for (let i = 0; i < numberPrevious; i++) {
        let name = hrefParams.get(`name${i}`);
        let url = hrefParams.get(`url${i}`);
        document.querySelector(`input#rival_name${i}`).value = name;
        document.querySelector(`input#rival_url${i}`).value = url;
      }
    }

    btn.addEventListener("click", e => {
      const number = document.querySelectorAll(".form-screen-inputs-field-container").length;
      let href = "/rivals/new.add?";
      for (let i = 0; i < number; i++) {
        let name = document.querySelector(`input#rival_name${i}`).value;
        let nameEnc = encodeURIComponent(name);
        let url = document.querySelector(`input#rival_url${i}`).value;
        let urlEnc = encodeURIComponent(url);
        i === number - 1 ? (href += `name${i}=${nameEnc}&url${i}=${urlEnc}`) : (href += `name${i}=${nameEnc}&url${i}=${urlEnc}&`);
      }
      btn.setAttribute("href", href);
    });
  }
});
