const showCardInTheView = elem => {
  let docViewTop = $(window).scrollTop();
  let docViewBottom = docViewTop + $(window).height();
  let elemTop = $(elem).offset().top;
  let elemShow = elemTop + 150;
  if (elemShow <= docViewBottom) {
    $(elem).addClass("show");
  } else {
    $(elem).removeClass("show");
  }
};

const isLoadBtnInTheView = elem => {
  let docViewTop = $(window).scrollTop();
  let docViewBottom = docViewTop + $(window).height();
  let btnTop = $(elem).offset().top;
  let btnBottom = btnTop + $(elem).height();
  if (btnBottom <= docViewBottom) {
    return true;
  } else {
    return false;
  }
};

const requestMoreUpdates = () => {
  $(".load-more-button").css("display", "none");
  $(".loading-gif").css("display", "block");
  let cardsArr = [...document.querySelectorAll(".dashboard-screen-rivals-feed-card")];
  $.ajax({
    type: "GET",
    url: `${$(".load-more-button").attr("href")}?feed_offset=${cardsArr.length}`,
    dataType: "script",
    success: function() {
      $(".load-more-button").css("display", "block");
      $(".loading-gif").css("display", "none");
      let cardsArr = [...document.querySelectorAll(".dashboard-screen-rivals-feed-card")];
      cardsArr.forEach(card => {
        showCardInTheView(card);
      });
      if ($(".no-more-updates-info").length) {
        $(".load-more-button").css("display", "none");
        $(".loading-gif").css("display", "none");
      } else {
        if (isLoadBtnInTheView($(".load-more-button"))) {
          requestMoreUpdates();
        }
      }
    }
  });
};

$(document).on("turbolinks:load", function() {
  const header = $(".dashboard-screen-rivals-feed-header");
  if (header.length > 0) {
    let cardsArr = [...document.querySelectorAll(".dashboard-screen-rivals-feed-card")];
    cardsArr.forEach(card => {
      showCardInTheView(card);
    });
    const loadBtn = $(".load-more-button");
    if (isLoadBtnInTheView($(loadBtn))) {
      requestMoreUpdates();
    }
    $(loadBtn).on("click", function(e) {
      e.preventDefault();
      requestMoreUpdates();
    });

    // loading after scroll

    $(window).on("scroll", function() {
      let docViewTop = $(window).scrollTop();
      let docViewBottom = docViewTop + $(window).height();

      let btnTop = $(".load-more-container").offset().top;
      let btnBottom = btnTop + $(".load-more-container").height() + 10;

      if (docViewBottom >= btnBottom) {
        if ($(loadBtn).css("display") === "block" || $(loadBtn).css("display") === "inline") {
          $(loadBtn).triggerHandler("click");
        }
      }

      cardsArr = [...document.querySelectorAll(".dashboard-screen-rivals-feed-card")];
      cardsArr.forEach(card => {
        showCardInTheView(card);
      });
    });
  }
});
